@use "sass:color";

.card {
  background-color: rgba($white, 0.75);

  &-header {
    //color: $white;
    //background-color: $indigo;

    h1, h2, h3, h4, h5, h6 {
      font-size: 1.1rem;
      font-weight: 600;
      font-family: "Martel Sans", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      margin-top: 0;
      margin-bottom: -0.5ex;
    }
  }

  &-body {

    .card-text {

      p {
        line-height: 1.5;
        font-family: "Martel Sans", sans-serif;
      }
    }

    .btn {
      padding-bottom: 4px;
      padding-top: 8px;
      font-size: 120%;
      display: block;
    }
  }

  .square-corners {
    border-radius: 0;
    border: 1px solid rgba($black, 0.85);
  }
}


