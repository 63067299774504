@use "sass:color";

//https://getbootstrap.com/docs/4.5/getting-started/theming/

// paper, ink, neutral colors
$white:    #fff9f6;
$black:    #110311;
$gray-100: mix($white, $black, 95%);
$gray-200: mix($white, $black, 88%);
$gray-300: mix($white, $black, 75%);
$gray-400: mix($white, $black, 60%);
$gray-500: mix($white, $black, 50%);
$gray-600: mix($white, $black, 40%);
$gray-700: mix($white, $black, 25%);
$gray-800: mix($white, $black, 15%);
$gray-900: mix($white, $black, 5%);

// core colors
$blue:    #7bace5;
$indigo:  #584068;
$purple:  #4a2040;
$pink:    #7a3363;
$red:     #761e35;
$orange:  #d64933;
$yellow:  #efdd8d;
$green:   #58641d;
$teal:    #568983;
$cyan:    #074c56;

// theme colors
$primary:       $cyan;
$secondary:     $gray-600;
$success:       $green;
$info:          $indigo;
$warning:       $orange;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;


// element colors
$link-color: $orange;


// typefaces
@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;600&family=Martel:wght@400;700&display=swap');

$variable-prefix: bs-;
$font-family-sans-serif: "Martel Sans", sans-serif;
$font-family-base: var(--font-family-sans-serif);

