@use "sass:color";

nav {
  font-family: 'Martel Sans', sans-serif;
  font-weight: 700;
}

#nav-top {
  font-size: 115%;
  text-transform: uppercase;

  margin-left: 0.5em;
  margin-right: 0.5em;

  #brand {
    transform-origin: left center;
  }

  // Mobile
  @include media-breakpoint-down(sm) {
    margin: 0;
    padding: 0;

    #brand, .navbar-toggler {
      margin: 8px 16px;
    }

    .navbar-toggler {
      font-size: 1rem;
      color: $indigo;
    }

    .navbar-collapse {
      padding: 1ex 0;
      background-color: $gray-200;
      background: linear-gradient(177deg,
          mix($gray-300, $teal, 50%) 0,
          mix($gray-200, $gray-300, 50%) 40%,
          $gray-200 80%,
          $gray-200 100%
      );

      flex-direction: row;

      .navbar-nav {

      }

      .nav-social {
        transform-origin: bottom left;
        float: left;
        height: 0;
        overflow: visible;
        margin-top: -8vw;

        padding: 0 1rem;
        font-size: 150%;
        opacity: 0.5;

        .nav-social-wrapper {
          justify-content: flex-start;
        }
      }
    }
  }

  // Phone
  @include media-breakpoint-down(xs) {

    .navbar-collapse {

      .nav-social {
        margin-top: -11.5vw;
      }
    }

  }

  // Tablet+
  @include media-breakpoint-up(md) {

    .navbar-collapse {
      flex: 1 1 auto;
      width: 100%;

      .navbar-nav {
        width: 100%;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
      }

      .navbar-expand-md {
        justify-content: center;
      }

      .nav-social {
        transform-origin: right center;
      }
    }

    * {
      vertical-align: bottom;
    }
  }

  .nav-item {
    $nav-color: $purple;

    .nav-link {
      color: rgba($nav-color, 0.75);
      white-space: nowrap;

      &:hover {
        color: rgba($nav-color, 1);
      }

      // Tablet+
      @include media-breakpoint-up(sm) {
        padding-top: 1.25ex;
      }
    }

    // Tablet+ ONLY
    @include media-breakpoint-up(md) {

      .nav-link {
        padding-left: 1em;
        padding-right: 1em;
      }
    }

    // Mobile
    @include media-breakpoint-down(sm) {

      font-size: 3.5vw;

      .nav-link {
        z-index: 2;
        font-size: 115%;
        padding: 0.5ex 1rem;

        display: inline-block; // don't make the whole screen clickable

        &.dropdown-toggle {
          pointer-events: none;
          font-size: 70%;
          letter-spacing: 0.5em;

          border-top: 2px solid rgba($black, 0.1);
          border-bottom: 2px solid rgba($black, 0.1);
          color: $gray-500;
          line-height: 1;
          padding-top: 1.5ex;
          padding-bottom: 1ex;
          margin-bottom: 1ex;
          display: block;

          &::after {
            display: none;
          }
        }
      }

      .dropdown-menu {
        display: block;
        border: none;
        background: transparent;
        text-align: right;
        margin: 0;
        padding: 0;
        font-weight: 200;

        .nav-link {
          color: $indigo;
          font-size: 150%;
          padding-top: 0.5ex;
          padding-bottom: 0.5ex;
          //color: mix($nav-color, $black, 0.1);
        }
      }
    }

    // Menu gets bigger on smaller devices
    @include media-breakpoint-down(xs) {
      font-size: 5vw;
    }
  }
}

.nav-social {
  width: 75px;
  opacity: 0.75;

  @include media-breakpoint-down(sm) {
    width: auto;
    padding: 0;
  }
}

.nav-social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;

  a.nav-site-icon {
    $icon-size: 28px;

    width: $icon-size;
    height: $icon-size;
    flex: 0 0 auto;
    margin: 1px;
    padding: 4px;
    display: inline-block;
    color: red;
    opacity: 0.75;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(sm) {
      $icon-size: 8vw;

      width: $icon-size;
      height: $icon-size;
    }

    @include media-breakpoint-down(xs) {
      $icon-size: 11vw;

      width: $icon-size;
      height: $icon-size;
    }

    &:hover {
      opacity: 1;
    }
  }

  #icon-facebook {
    background-image: url('./icon_facebook.svg');
  }

  #icon-instagram {
    background-image: url('./icon_instagram.svg');
  }
}

.header-band {
  display: block;
  mix-blend-mode: multiply;
  background-color: rgba($teal, 0.85);
  height: 5px;
  margin-bottom: 1.5em;
}
