@use "sass:color";

.photo-gallery {
  clear: both;
  background-color: $gray-900;
  color: $gray-100;
  border-top: 2px solid $gray-300;
  border-bottom: 2px solid $gray-300;
  margin-bottom: 1.5em;
  padding: 1.5em;
  margin-top: 1.5ex;

  .gallery-title {
    text-align: center;
    color: $teal;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.0rem;
    letter-spacing: 0.2rem;
    margin-bottom: 2ex;
  }

  .react-photo-gallery--gallery {

    img {
      border-radius: 1px;

      &:hover {
        outline: none;
        box-shadow: 0 0 0 2px $black, 0 0 0 4px $orange;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px $black, 0 0 0 4px $yellow;
      }
    }
  }
}

.react-images__view {


  img {
    max-height: 90vh;
    // Don't define max-width, it breaks the scroll animation
  }
}
