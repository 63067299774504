@use "sass:color";

// Scroll bars
$scroll-bar-width: 7px;

* {
  scrollbar-width: thin;
  scrollbar-color: rgba($gray-200, 1)
                   rgba($gray-700, 1);
}

*::-webkit-scrollbar {
  width: $scroll-bar-width;
  height: $scroll-bar-width;
}

*::-webkit-scrollbar-track {
  background: rgba($gray-200, 1);
  position: absolute;
}

*::-webkit-scrollbar-thumb {
  background: rgba($gray-600, 1);
  position: absolute;
  border-radius: 3px;

  &:hover,
  &:active {
    background: rgba($gray-700, 1);
  }
}

*::-webkit-scrollbar-corner {
  background: rgba($gray-700, 1);
}
