@use "sass:color";

footer {
  color: $gray-300;
  background-color: $gray-800;
  border-top: 5px solid $teal;
  padding: 20px;

  font-size: 90%;
  margin-top: 1.5rem;
}
