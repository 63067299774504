@use "sass:color";

.jumbotron {
  margin-bottom: 0;
  height: 50vh;
  min-height: 350px;
  max-height: 1000px;

  .card {
    max-width: 20em;

    &-text {
      font-weight: 600;
    }
  }

  // Mobile
  @include media-breakpoint-down(sm) {
    min-height: 500px;
    max-height: 80vh;
    height: auto;
  }
}


#page-home {

  .header-band:first-of-type {
    $band-height: 50px;

    // TODO: washi-tape?
    height: $band-height;
    margin: 0;
    position: relative;

    &::after {
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      top: $band-height;
      background: linear-gradient(0deg, rgba($black, 0) 0%, rgba($black, 0.05) 80%, rgba($black, 0.2) 100%);
      z-index: 1;
      content: "";
    }
  }

  #brand a {
    height: 100px;
    width: 150px;
    margin-right: calc(78px - 150px); // 78 - other pages' brand width
    filter: drop-shadow(1px 1px 0px rgba($white, 1))
            drop-shadow(0 -1px 2px rgba($white, 0.8))
            drop-shadow(0 0 5px rgba($white, 0.5));
    z-index: 3;
  }

  .service-card {
    width: 350px; // fixme
    margin: 0 0.5rem 1rem;
    padding: 0;
    border-color: rgba($gray-500, 0.5);
    transition: 0.25s ease-in-out;
    transform: scale(1);
    box-shadow: 0 0 0 rgba($black, 0);

    .card-divider {
      position: relative;
      width: 100%;
      height: 1px;
      background-color: rgba($gray-500, 0.5);

      &::before {
        display: block;
        width: 100%;
        height: 10px;
        position: absolute;
        bottom: 1px;
        background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.05) 80%, rgba($black, 0.2) 100%);
        z-index: 1;
        content: "";
      }
    }

    img {
      transition: 0.25s all ease-in-out;
      position: relative;
      filter: saturate(0.75);
      width: 100%;
      height: auto;
    }


    &:hover {
      transform: scale(1.03);
      box-shadow: 1px 1px 5px rgba($black, 0.3);

      img {
        filter: saturate(1);
      }
    }

    // Mobile
    @include media-breakpoint-down(sm) {
      width: auto;

      img {
        height: auto;
      }
    }
  }

}
