
#brand {
  display: block;
  overflow: visible;
  position: relative;
  max-height: 50px;
  z-index: 2;

  h1 {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  a {
    transition: all 500ms;
    vertical-align: baseline;
    display: block;
    cursor: pointer;
    height: 50px;
    width: 78px;
    margin: 0;
    padding: 10px;
    background-image: url(brand_miaou.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:focus {
      outline: none;


    }
  }
}
