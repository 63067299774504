@use "sass:color";

// TODO: Responsive etc.
// https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
// sizes: 576 | 768 | 992 | 1200; invocation --v
//    @include media-breakpoint-[up|down|only]([xs|sm|md|lg|xl])

html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(./watercolor-paper-bg.jpg);
  background-repeat: repeat;
  background-size: 320px;

  @media (min-width: 1600px) {
    font-size: 120%;

    #brand,
    .nav-social {
      transform: scale(1.2);
    }

    .container {
      max-width: 1300px;
    }
  }

  @media (min-width: 1800px) {
    font-size: 150%;

    #brand,
    .nav-social {
      transform: scale(1.35);
    }

    .container {
      max-width: 1500px;
    }
  }

  @media (min-width: 2500px) {
    font-size: 200%;

    #brand,
    .nav-social {
      transform: scale(1.8);
    }

    .container {
      max-width: 2000px;
    }
  }

  header {
    flex: 0 0 auto;
    z-index: 2;
    filter: blur(0); // REQUIRED for modal blur support

  }

  main {
    flex: 1 1 100%;
    z-index: 1;
    filter: blur(0); // REQUIRED for modal blur support

    .container > {

      h1, h2, h3 {
        color: $indigo;
      }

      p, ul, ol, dl {
        font-family: 'Martel', serif;
        line-height: 2.25;
      }

      .content-img {
        border: 1px solid rgba($gray-500, 0.8);
        margin-bottom: 1.5rem;

        &.float-right {
          margin-left: 1.5rem;
        }

        &.float-left {
          margin-right: 1.5rem;
        }
      }

      // Tablet AND UP
      @include media-breakpoint-up(md) {

        .content-img {
          float: right;
          margin-left: 2ex;
          margin-bottom: 2ex;
        }
      }

      // Tablet ONLY
      @include media-breakpoint-only(md) {

        .content-img {
          max-width: 40vw;
          height: auto;
        }
      }

      // Mobile
      @include media-breakpoint-down(sm) {

        .content-img {
          float: none;
          margin: 0;
          width: 100%;
          height: auto;
          margin-bottom: 2ex;
        }
      }
    }
  }

  footer {
    flex: 0 0 auto;
    filter: blur(0); // REQUIRED for modal blur support
  }

}

